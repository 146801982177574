import { Component } from '@angular/core';

@Component({
  selector: 'app-timeline-maintenance-form',
  templateUrl: './timeline-maintenance-form.component.html',
  styleUrl: './timeline-maintenance-form.component.scss'
})
export class TimelineMaintenanceFormComponent {

}
