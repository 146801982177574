import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from "../../../../models/user.model";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-timeline-modal',
    templateUrl: './timeline-modal.component.html',
    styleUrl: './timeline-modal.component.scss'
})
export class TimelineModalComponent {
    @Input() public data: any;
    @Input() public formType: 'note'|'booking'|'maintenance' = 'note';
    @Output() public historyChange: EventEmitter<[]> = new EventEmitter<[]>();
    public submitted: boolean = false;
    public users: User[] = [];

    public constructor(
        public activeModal: NgbActiveModal,
    ) {
    }
}
