<div class="modal-header">
    <h5 class="modal-title fw-bold">{{ data.action ?? 'Add' }} {{ data.formType | titlecase }}</h5>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
    <ng-container *ngIf="formType === 'note'">
        <app-timeline-note-form></app-timeline-note-form>
    </ng-container>

    <ng-container *ngIf="formType === 'booking'">
        <app-timeline-booking-form></app-timeline-booking-form>
    </ng-container>

    <ng-container *ngIf="formType === 'maintenance'">
        <app-timeline-maintenance-form></app-timeline-maintenance-form>
    </ng-container>
</div>

<div class="modal-footer justify-content-between">
    <button (click)="activeModal.dismiss()" class="btn btn-link">cancel</button>
<!--    <button class="btn btn-primary" form="bookingForm"><i class="fa-solid fa-check"></i> Save</button>-->
</div>
