import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from "../../../models/user.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {TimelineService} from "../../services/timeline.service";
import {ApiService} from "../../services/api.service";

@Component({
    selector: 'app-timeline-note-form',
    templateUrl: './timeline-note-form.component.html',
    styleUrl: './timeline-note-form.component.scss'
})
export class TimelineNoteFormComponent {
    @Input() public data: any;
    @Output() public HistoryChange: EventEmitter<[]> = new EventEmitter<[]>();
    public users: User[] = [];
    public leads: any[] = [];
    public noteForm: FormGroup;

    public constructor(
        public activeModal: NgbActiveModal,
        public dialog: MatDialog,
        public apiService: ApiService,
        private timelineService: TimelineService,
        private fb: FormBuilder,
    )
    {
    }

    public ngOnInit(): void {
        console.log('note form data', this.data);

        this.noteForm = this.fb.group({
            title: [this.data?.title ?? null, [Validators.required, Validators.minLength(2)]],
            description: [this.data?.description ?? null, Validators.required],
        });

        this.apiService.get('users').subscribe(
            response => this.users = response,
            error => this.apiService.handleError(error)
        );
    }

    public addNote(): void {
        // if (this.globalService.checkPermission('Add Note', true) && this.noteForm.valid) {
            const type = 'Note';

            this.timelineService.AddNote(this.noteForm.value).subscribe(
                response => this.RequestSuccess(response, type),
                error => this.timelineService.handleError(error)
            );
        // }
    }

    public deleteNote(index, id, type): void {
        this.timelineService.DeleteNote(id).subscribe(
            response => this.RequestDeleteSuccess(response, index),
            error => this.timelineService.handleError(error)
        );
    }

    public RequestDeleteSuccess(res, index): void {
        this.data?.History.splice(index, 1);
        this.HistoryChange.emit(this.data?.History);
        // this.toastr.success('Deleted record successfully.', 'Record Deleted', {tapToDismiss: true});
    }

    public RequestSuccess(res, type: string): void {
        this.data?.History.push(res);
        this.HistoryChange.emit(this.data?.History);
        // this.toastr.success('This ' + type + ' has successfully been added.', 'Record Added', {tapToDismiss: true});
        this.activeModal.close(res);
    }
}
