import {HttpClient}    from '@angular/common/http';
import {Injectable}    from '@angular/core';
import {Observable}    from 'rxjs';
import {BaseService}   from './base.service';
import {ToastService} from "./toast.service";

@Injectable({
    providedIn: 'root'
})
export class TimelineService extends BaseService
{
    public constructor(
        public override toastService: ToastService,
        private http: HttpClient,
    )
    {
        super(toastService);
    }

    public AddNote(data): Observable<any>
    {
        const perfData = {
            // VehicleId: this.globalService.getVehicle(),
            // UserId: this.globalService.getUsersID()
        };

        const postData = Object.assign({}, data, perfData);

        return this.http.post(`${this.apiUrl}/VehicleNote`, postData).pipe();
    }

    public AddContactNote(data): Observable<any>
    {
        const perfData = {
            // VehicleId: this.globalService.getVehicle(),
            // UserId: this.globalService.getUsersID()
        };

        const postData = Object.assign({}, data, perfData);

        return this.http.post(`${this.apiUrl}/ContactNote`, postData).pipe();
    }

    public AddBooking(data): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/VehicleBooking`, data).pipe();
    }

    public GetBookingStatuses(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/BookingStatus`).pipe();
    }

    public ChangeBookingStatus(id, statusId): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/VehicleBooking/UpdateStatus/${id}`, {statusId}).pipe();
    }

    public AddMaintenance(data): Observable<any>
    {
        const perfData = {
            // VehicleId: this.globalService.getVehicle(),
            // UserId: this.globalService.getUsersID()
        };

        const postData = Object.assign({}, data, perfData);

        return this.http.post(`${this.apiUrl}/VehicleMaintenance`, postData).pipe();
    }

    public EditNote(id, body): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/VehicleNote/${id}`, body).pipe();
    }

    public EditContactNote(id, body): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/ContactNote/${id}`, body).pipe();
    }

    public EditBooking(id, body): Observable<any>
    {

        return this.http.put(`${this.apiUrl}/VehicleBooking/${id}`, body).pipe();
    }

    public EditMaintenance(id, body): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/VehicleMaintenance/${id}`, body).pipe();
    }

    public DeleteNote(id): Observable<any>
    {
        return this.http.delete(`${this.apiUrl}/VehicleNote/${id}`).pipe();
    }

    public DeleteBooking(id): Observable<any>
    {
        return this.http.delete(`${this.apiUrl}/VehicleBooking/${id}`).pipe();
    }

    public DeleteMaintenance(id): Observable<any>
    {
        return this.http.delete(`${this.apiUrl}/VehicleMaintenance/${id}`).pipe();
    }
}
