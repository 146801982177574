import { Component } from '@angular/core';

@Component({
  selector: 'app-timeline-booking-form',
  templateUrl: './timeline-booking-form.component.html',
  styleUrl: './timeline-booking-form.component.scss'
})
export class TimelineBookingFormComponent {

}
